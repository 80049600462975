import React from "react"
import { graphql, Link } from "gatsby"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageLayout, TwoOptions } from "../components/PageLayout"
import { ArrowCircleRightIcon } from "@heroicons/react/solid"
import tooMuchCoffeeGIF from "../../content/assets/mitch-too-much-coffee-4x5.gif"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <>
      {/* <Layout location={location} title={siteTitle}> */}
      <SEO title="404: Not Found" ogImage={data?.socialImage?.childImageSharp?.fixed.src} />
      <PageLayout>
        <div className="flex flex-col w-full md:flex-row md:max-h-[50%] md:justify-center max-w-screen-lg mb-4 border-0">
          <div className="flex flex-col items-start border-0 px-4">
            <h1 className="text-primaryBlueNormal my-1">Oh the sadness!</h1>
            <h2 className="text-primaryOrangeNormal mt-1">404: Not Found</h2>
            <p className="my-1 italic">That page doesn't exist.</p>
            <p className="hidden md:block my-1">
              Here, watch Coach Mitch enjoy far too much espresso instead.
            </p>
            <p className="font-semibold mt-0">
              You probably want to learn more about how the coaches at the Liftology Project can
              help you achieve your health goals right?
            </p>
            <TwoOptions>
              <div className="w-full md:w-1/2">
                <Link to="/free-assessment?g=wl">
                  <div
                    className="flex flex-row pl-4 md:items-center md:justify-center w-full font-sans text-2xl text-center shadow-xl rounded-xl border-2 border-primaryBlueNormal bg-primaryBlueNormal text-white hover:text-primaryBlueNormal hover:bg-white hover:border-2
              hover:border-primaryBlueNormal uppercase py-2 font-semibold">
                    Lose weight
                    <ArrowCircleRightIcon className="ml-1 h-7 w-7" />
                  </div>
                </Link>
              </div>
              <div className="w-full md:w-1/2">
                <Link to="/free-assessment?g=sg">
                  <div
                    className="flex flex-row pl-4 md:items-center md:justify-center w-full font-sans text-2xl text-center shadow-xl rounded-xl border-2 border-primaryOrangeNormal bg-primaryOrangeNormal text-white hover:text-primaryOrangeNormal hover:bg-white hover:border-2
              hover:border-primaryOrangeNormal uppercase py-2 font-semibold">
                    Get stronger
                    <ArrowCircleRightIcon className="ml-1 h-7 w-7" />
                  </div>
                </Link>
              </div>
            </TwoOptions>
          </div>
          <div className="flex flex-col items-center">
            <img className="hidden md:block w-full" src={tooMuchCoffeeGIF} alt="Too Much Coffee!" />
          </div>
        </div>
      </PageLayout>
    </>
    //  </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    socialImage: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-social-1200x628.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 1200, height: 628, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
